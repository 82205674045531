import React, {useEffect, useMemo, useRef, useState} from 'react';
import cc from 'classcat';
import {Capacitor} from '@capacitor/core';
import {FaRegCalendarAlt, FaUtensils, FaPercentage} from 'react-icons/fa';
import {FiChevronRight} from 'react-icons/fi';
import {useNavigate, useParams} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import dayjs from 'dayjs';
import {Keyboard, KeyboardResize} from '@capacitor/keyboard';

import Layout from '@/layouts';
import OverlayHeader from '@/layouts/OverlayHeader';
import {useInfoModal} from '@/hooks/useInfoModal';
import {isOpenToast, ToastMessage, user} from '@/store';
import {getContentsDetail, requestPayments} from '@/services/magazine';
import {addComma, generateRandomString, isIos, isAndroid} from '@/utils';
import BrandShareBottomSheet from '@/components/brandShareBottomSheet';
import FrequencyQuestion from '@/components/frequencyQuestion';

import styles from './styles.module.scss';

export const GiftcardDetail = () => {
  const offsetTop = useRef(0);
  const navigator = useNavigate();
  const [info, setInfo] = useState<MagazineItem>();
  const [phoneNumber, setPhoneNumber] = useState('');
  const param = useParams();
  const accessToken = useRecoilValue(user);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const [openQuestion, setOpenQuestion] = useState(false);
  const [maxDiscountAmt, setMaxDiscountAmt] = useState(0);
  const [isFixedBtn, setIsFixedBtn] = useState(false);
  const [minAmt, setMinAmt] = useState(0);

  const getData = async (id: string) => {
    const res: MagazineItem = await getContentsDetail(accessToken, Number(id));
    setInfo(res);
    setMaxDiscountAmt(res.popupInfo?.options[0].benefitMaxDiscount || 0);
    setMinAmt(res.popupInfo?.options[0].benefitMinAmount || 0);
  };

  const isSoldOut = useMemo(() => {
    if (info) {
      const limit = info.popupInfo?.options[0].limit;
      const soldCnt = info.popupInfo?.options[0].soldCount;
      return limit === soldCnt;
    }
    return false;
  }, [info]);

  const receiveGift = async () => {
    const orderId = generateRandomString();
    const optionId = info?.popupInfo?.options[0].id;
    const title = info?.title;

    try {
      const requestParam: PaymentRequestParam = {
        orderId,
        popup: info?.id || -1,
        option: optionId,
        orderName: title || '',
        paymentKey: orderId,
        userPhone: phoneNumber,
        purchaseCount: 1,
        purchaseAmount: 0,
      };
      const res = await requestPayments(requestParam, accessToken);
      getData(param.id as string);
      if (res) {
        showModal();
      }
    } catch (e) {
      setToastMessage('에러가 발생했습니다');
      setIsOpenToast(true);
    }
  };
  const changePhoneNumber = ($event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber($event.target.value);
  };
  const btnTxt = useMemo(() => {
    if (!accessToken) {
      return '할인권 받기';
    }
    if (info?.isBought) {
      return '발급 완료';
    }
    if (isSoldOut) {
      return '선착순 마감';
    }
    return '할인권 받기';
  }, [accessToken, isSoldOut, info]);

  const initKeyboard = () => {
    Keyboard.addListener('keyboardWillShow', info => {
      console.log('keyboard will show with height:', info.keyboardHeight);
      const mode: KeyboardResize = KeyboardResize.Body; // 타입 정의에 맞는 값 설정
      Keyboard.setResizeMode({
        mode: mode,
      });
    });

    Keyboard.addListener('keyboardDidShow', info => {
      window.scrollTo(0, offsetTop.current + info.keyboardHeight);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      console.log('keyboard will hide');
    });

    Keyboard.addListener('keyboardDidHide', () => {
      console.log('keyboard did hide');
    });
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      initKeyboard();
    }
    if (window) {
      setIsFixedBtn(window.innerHeight > 750);
    }
  }, []);

  useEffect(() => {
    if (param.id) {
      getData(param.id);
    }
  }, [param]);
  const modal = useInfoModal(
    <div className={styles.mWrapper} style={{width: '100%'}}>
      <div
        className={styles.mtit}
        style={{
          color: '#000',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '700',
          lineHeight: '140%' /* 25.2px */,
          letterSpacing: '-0.36px',
        }}>
        할인권이 발급되었습니다
      </div>
      <div
        className={styles.mInfo}
        style={{
          color: '#78737D',
          textAlign: 'center',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '140%' /* 25.2px */,
          letterSpacing: '-0.32px',
          marginTop: '10px',
        }}>
        기한 내에 매장에 방문하여 사용해 주세요
      </div>
      <div className={styles.mQuestion}>
        <div
          className={styles.mQitem}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}>
          <span
            onClick={() => navigator('/order/benefits')}
            style={{
              color: '#000',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '100%' /* 25.2px */,
              letterSpacing: '-0.28px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}>
            내역 바로가기
          </span>
          <FiChevronRight size={12} />
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <button
          style={{
            borderRadius: '5px',
            border: '1px solid var(--G2, #E9E7EC)',
            background: '#fff',
            width: '100%',
            height: '50px',
            color: '#9F9CA3',
            marginTop: '20px',
          }}
          onClick={() => modal.closeModal()}>
          닫기
        </button>
      </div>
    </div>,
  );
  const [isOpenShareSheet, setIsOpenShareSheet] = useState(false);
  const showModal = () => {
    modal.showModal();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout.Main>
      <div
        className={cc([
          styles.wrapper,
          {[styles.ios]: Capacitor.getPlatform() === 'ios'},
        ])}
        style={{minHeight: '100vh', background: '#fff'}}>
        <OverlayHeader
          isScrolled={true}
          title={'할인권'}
          clickShare={() => setIsOpenShareSheet(true)}
          back={() => navigator(-1)}
        />
        <div
          className={`${styles.giftCardMain} ${
            isIos() ? styles.ios : isAndroid() ? styles.android : ''
          }`}>
          {/* 사진 제목 */}
          <div className={styles.defaultInfo}>
            <div
              className={styles.defImg}
              style={{
                background: `url('${info?.imageUrl}') no-repeat center / cover`,
              }}></div>
            <div className={styles.tit}>{info?.title}</div>
          </div>
          {/* 기본사항 */}
          <div className={styles.descriptions}>
            <div className={`${styles.descItem} ${styles.schedule}`}>
              {/* 사용가능 일자 */}
              <FaRegCalendarAlt size={16} color="#e91e63" />
              <span>
                ~
                {`${dayjs(info?.popupInfo?.options[0].expired).format(
                  'MM월DD일',
                )}`}
                까지 사용 가능
              </span>
            </div>
            <div className={styles.descItem}>
              {/* 매장 방문 사용 */}
              {/* <FaHotel size={16} color="#424242" /> */}
              <span className={styles.mejangIcon}></span>
              <span>{`${info?.popupInfo?.options[0].benefitUsage}`}</span>
            </div>
            <div className={styles.descItem}>
              {/* 메뉴 제한 없음 */}
              <FaUtensils size={16} color="#424242" />
              <span>{`${info?.popupInfo?.options[0].benefitMenuLimit}`}</span>
            </div>
            <div className={styles.descItem}>
              {/* 50,000원 이상 결제시 */}
              {/* <FaTicketAlt size={16} color="#424242" /> */}
              <span className={styles.cardIcon}></span>
              {minAmt === 0 ? (
                <span>최소 주문 금액 제한없음</span>
              ) : (
                <span>
                  {addComma(info?.popupInfo?.options[0].benefitMinAmount || 0)}
                  원 이상 결제 시
                </span>
              )}
            </div>
            <div className={styles.descItem}>
              {/* 최대 할인  금액 */}
              <FaPercentage size={16} color="#424242" />
              {maxDiscountAmt === 0 ? (
                <span>최대 할인 금액 제한없음</span>
              ) : (
                <span>최대 할인 금액 {addComma(maxDiscountAmt)}원</span>
              )}
            </div>
          </div>
          {/* 휴대폰 번호 */}
          <div className={styles.phoneNumber}>
            <div className={styles.phoneLabel}>휴대폰 번호</div>
            <div className={styles.phone}>
              <input
                type="tel"
                placeholder="휴대폰 번호를 입력해주세요"
                onChange={changePhoneNumber}
              />
            </div>
          </div>
          {/* 발급 유의사항 */}
          <div className={styles.warning}>
            <div className={styles.warningTit}>발급 유의사항</div>
            <ul className={styles.warningList}>
              <li>매장 방문 결제 시 사용할 수 있습니다.</li>
              <li>사용 기간을 유의하여 방문해 주세요.</li>
            </ul>
          </div>
          {/* 자주묻는 질문 */}
          <div className={styles.question}>
            <div className={styles.qItem} onClick={() => setOpenQuestion(true)}>
              <span>자주 묻는 질문</span>
              <FiChevronRight size={12} />
            </div>
          </div>
        </div>
        <div
          className={`${styles.bottomBtn} ${
            isFixedBtn ? styles.normal : styles.ex
          }`}>
          {/* 이미 발급받은 내역이 있어용 */}
          {info?.isBought && (
            <div className={styles.already}>
              <span className={styles.aTit}>이미 발급받은 내역이 있어요</span>
              <span
                className={styles.aLink}
                onClick={() => navigator('/order/benefits')}>
                <span>바로가기</span>
                <FiChevronRight size={12} />
              </span>
            </div>
          )}
          {/* 할인권이 모두 소진되었어용 */}
          {accessToken && !info?.isBought && isSoldOut && (
            <div className={styles.already}>
              <span className={styles.aTit}>할인권이 모두 소진되었어요</span>
              <span
                className={styles.aLink}
                onClick={() =>
                  navigator(`/giftcard/wait/${param.id}`, {
                    state: {
                      detailInfo: info,
                      optionId: info?.popupInfo?.options[0].id,
                    },
                  })
                }>
                <span>다음 오픈 알림 받기</span>
                <FiChevronRight size={12} />
              </span>
            </div>
          )}
          <div
            className={styles.submitBtnWrapper}
            style={{marginTop: '10px', position: 'relative'}}>
            <span
              className={`${styles.noticeTxt} ${
                accessToken && info && !info?.isBought
                  ? styles.show
                  : styles.hidden
              }`}>
              {info?.popupInfo?.msgTxt}
            </span>
            <button
              onClick={() => receiveGift()}
              disabled={
                phoneNumber.length === 0 || info?.isBought || isSoldOut
              }>
              {btnTxt}
            </button>
          </div>
        </div>
        {modal.renderModal()}
        <BrandShareBottomSheet
          isOpen={isOpenShareSheet}
          setIsOpen={setIsOpenShareSheet}
          item={{
            title: info?.title || '',
            info: info?.subtitle || '',
            imageList: [info?.imageUrl || ''],
          }}
        />

        <FrequencyQuestion
          isOpen={openQuestion}
          close={() => setOpenQuestion(!openQuestion)}
        />
      </div>
    </Layout.Main>
  );
};
